<template>
    <Section class="associate-partner-section">
        <div class="large-body-text">
            <p>{{ $t('travelPage.sharedVision') }}</p>
        </div>
        <div class="associate-partner-text">
            <div class="bold-body-text">
                <p v-html="$t('travelPage.offerSeamless')"></p>
            </div>
        </div>
    </Section>
</template>

<script>

import Section from '../common/section.vue';

export default {
    name: 'AssociatePartnerSec',
    components: {
        Section,
    },
};
</script>

<style scoped>
@import '@/assets/style/travelPage/AssociatePartnerSec.scss';
</style>
