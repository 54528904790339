<template>
    <div v-if="visible" class="modal-overlay" @click.self="close">
        <div class="modal-body">
            <div class="close-button" @click="close">
                <img src="../../assets/images/icons/close-button.svg" alt="close-btn">
            </div>


            <div class="main-content">

                <div v-if="showCopyMessage" class="copy-message">
                    <div class="text-xs">
                        <p> {{ $t('newsPage.linkCopied') }}</p>
                    </div>
                </div>

                <div class="news-btn" @click="close">
                    <div class="news-btn-img">
                        <img src="../../assets/images/icons/array-right.svg" alt="">
                    </div>
                    <div class="news-btn-text">
                        <p>{{ $t('news') }}</p>
                    </div>
                </div>




                <div class="modal-news-title">
                    <h3>{{ displayTitle }}</h3>
                </div>

                <div class="top-wrapper">
                    <div class="news-meta">
                        <div class="meta-text">
                            <p>{{ $t('news') }}</p>
                        </div>
                        <div class="meta-date">
                            <p>{{ displayDate }}</p>
                        </div>
                    </div>
                    <div class="copy-link-wrapper" @click="copyLink">
                        <div class="copy-link-img">
                            <img src="../../assets/images/icons/Insert-link-icon.svg" alt="InsertLink">
                        </div>
                    </div>
                </div>




                <div class="modal-news-img">
                    <img :src="displayImage" alt="newsImg" />
                </div>

                <div class="modal-news-desc" v-html="displayContent"></div>
            </div>

            <div class="sidebar">
                <div class="sidebar-item">
                    <div class="sidebar-title">
                        <h4>{{ $t('newsPage.notice') }}</h4>
                    </div>
                    <div class="sidebar-title-wrapper" v-for="item in filteredNoticeNews" :key="item.id"
                        :class="{ 'selected-news': selectedNewsId === item.id }">
                        <div class="sidebar-news-title">
                            <h5>{{ item.title }}</h5>
                        </div>
                        <div @click="viewNewsDetails(item)" class="news-read-more">{{ $t('readMore') }}</div>
                    </div>
                </div>

                <div class="sidebar-item">
                    <div class="sidebar-title">
                        <h4>{{ $t('newsPage.hotNews') }}</h4>
                    </div>
                    <div class="sidebar-title-wrapper" v-for="item in filteredHotNews" :key="item.id"
                        :class="{ 'selected-news': selectedNewsId === item.id }">
                        <div class="sidebar-news-title">
                            <h5>{{ item.title }}</h5>
                        </div>
                        <div class="sidebar-news-image">
                            <img :src="item.coverImg" alt="newsImg" />
                        </div>
                        <div @click="viewNewsDetails(item)" class="news-read-more">{{ $t('readMore') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick, ref, onMounted, watch } from 'vue';
import { fetchNewsList } from '../../api/api';

export default {
    name: 'NewsModal',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        news: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            scrollPosition: 0,
            pageSize: 4,
            pageIndex: 1,
            newsArr: [],
            filteredNoticeNews: [],
            filteredHotNews: [],
            filterType: '',
            selectedNews: null,
            selectedNewsId: null,
            showCopyMessage: false
        };
    },
    computed: {
        displayTitle() {
            return this.selectedNews ? this.selectedNews.title : this.news.title;
        },
        displayDate() {
            const timestamp = this.selectedNews ? this.selectedNews.createTime : this.news.createTime;
            return this.formatDate(timestamp);
        },
        displayImage() {
            return this.selectedNews ? this.selectedNews.coverImg : this.news.coverImg;
        },
        displayContent() {
            return this.selectedNews ? this.selectedNews.content : this.news.content;
        }
    },
    methods: {
        close() {
            console.log(this.news.id)
            this.$emit('update:visible', false);
            this.enableScroll();
            this.selectedNews = null;
            this.selectedNewsId = null;
            this.$emit('close');
        },

        disableScroll() {
            this.scrollPosition = window.scrollY;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            document.body.style.top = `-${this.scrollPosition}px`;
        },
        enableScroll() {
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.top = '';
            window.scrollTo(0, this.scrollPosition);
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
        async fetchNewsModal() {
            try {
                const res = await fetchNewsList(this.pageIndex, this.pageSize, this.filterType);
                if (res.success) {
                    this.newsArr = res.data.newsList;
                    this.filteredNoticeNews = this.newsArr.filter(item => item.type === 1);
                    this.filteredHotNews = this.newsArr.filter(item => item.type === 2);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        viewNewsDetails(newsItem) {
            this.selectedNews = newsItem;
            this.selectedNewsId = newsItem.id;
            this.$emit('update:visible', true);
        },
        saveToLocalStorage() {
            const ttl = parseInt(process.env.VUE_APP_NEWS_CACHE_TTL, 10);
            const now = new Date().getTime();
            const item = {
                data: {
                    filteredNoticeNews: this.filteredNoticeNews,
                    filteredHotNews: this.filteredHotNews,
                },
                expiry: now + ttl,
            };
            localStorage.setItem('modalNewsData', JSON.stringify(item));
        },
        loadFromLocalStorage() {
            const itemStr = localStorage.getItem('modalNewsData');
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            const now = new Date().getTime();
            if (now > item.expiry) {
                localStorage.removeItem('modalNewsData');
                return null;
            }
            return item.data;
        },
        async copyLink() {
            try {
                const link = window.location.href;
                await navigator.clipboard.writeText(link);

                this.showCopyMessage = true;
                setTimeout(() => {
                    this.showCopyMessage = false;
                }, 3000);
            } catch (error) {
                console.error('error:', error);
            }
        }
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                const localData = this.loadFromLocalStorage();
                if (localData) {
                    this.filteredNoticeNews = localData.filteredNoticeNews;
                    this.filteredHotNews = localData.filteredHotNews;
                } else {
                    this.fetchNewsModal();
                }
                this.disableScroll();
                nextTick(() => {
                    if (this.$refs.videoPlayer) {
                        this.$refs.videoPlayer.play();
                    }
                });
            } else {
                this.enableScroll();
                this.saveToLocalStorage();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/newsPage/newsModal.scss';
@import '../../assets/style/newsPage/newsMainSection.scss';
</style>
