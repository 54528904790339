<template>
    <div class="section-container">
        <div class="bg-image">
            <img src="/images/gamingPage/game-vectors.svg" alt="gaming-bg">
            <!-- <image-changer :image-src="'/images/gamingPage/game-vectors.svg'"
            :image-src-mobile="'/images/gamingPage/game-vectors-mob.svg'" alt-text="gaming-bg" /> -->
        </div>

        <div class="gaming-description">
            <div class="large-body-text">
                <p v-html="$t('gamingPage.gamingPageDescription')"></p>
            </div>
        </div>
        <div class="gaming-content">
            <div class="gaming-content-bg">
                <img :src=imageSource alt="">
            </div>
            <div class="gaming-video">
                <video :src=videoSource playsinline autoplay loop muted></video>
            </div>
            <div class="gaming-text">
                <div class="medium-body-text">
                    <p v-html="$t('gamingPage.usersWillEarnStars')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageChanger from '../../components/common/imageChanger.vue';
import useDarkMode from '@/darkMode';
export default {

    name: 'Description',
    components: {
        ImageChanger
    },

    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        videoSource() {
            return this.isDark
                ? '/images/gamingPage/gift-dark.mp4'
                : '/images/gamingPage/gift.mp4';
        },
        imageSource() {
            return this.isDark
                ? '/images/gamingPage/atm-star-bg-dark.webp'
                : '/images/gamingPage/atm-star-bg.webp';
        },
    }


};
</script>

<style lang="scss" scoped>
@import '@/assets/style/gamingPage/description.scss';
</style>