export default {
	home: 'Homepage',
	technology: 'Technology',
	ecosystem: 'Ecosystem',
	community: 'Community',
	partners: 'Partners',
	governance: 'Governance',
	explorer: 'Explorer',
	news: 'News',
	help: 'Help',
	webApp: 'Web app',
	games:'Games',
	dashboard: 'Dashboard',
	latestNews: 'latest news ',
	galaxy: 'ATm galaxy',
	features: ' features',
	gaming: 'Gaming',
	ecologi: 'Ecology',
	ecologiWallet: 'Ecologi Wallet:',
	traveling: 'Traveling',
	roadmap: ' ROADmap',
	travel: 'travel',
	apply: 'Apply now',
	downloadAtmApp: 'download atm.connect now',
	letsConnect: 'let’s connect',
	subscribe: 'Subscribe',
	enterEmail: 'Please enter your email...',
	name: 'Name*',
	emailAddress: 'Email address*',
	message: 'Your message..',
	submit: 'Submit',
	walletAddress: 'Wallet address',
	junkTitle: 'Connect the world, Connect the metaverse',
	junkContent: 'Unlock the power of connection and gain rewards effortlessly with our ATM network!',
	downloadAndroid: 'Download for Android',
	downloadIOS: 'Download for iOS',
	about: 'About',
	strengths: 'Strengths',
	create: 'Create',
	income: 'Income',
	learnMore: 'Learn more about pagerank service',
	comingSoon: 'Coming soon',
	train: 'Train',
	share: 'Share',
	avatars: 'AVATARS',
	viewAll: 'View all',
	view: 'View',

	noData: 'No data',
	readMore: 'Read more',
	noDataAvailable: 'No Data Available',
	notice: 'notice',
	all: 'all',
	caunter: {
		learnMore: 'Learn more'
	},

	title: {
		prAlgorithm: 'pr algorithm',
		autonomy: 'Autonomy',
		theTechnology: 'technology',
		consensusConnection: 'consensus connection',
		resources: 'resources',
		gamesFund: 'Games fund',
		thirdPartyEcosystem: 'third-party ecosystem',
		tokenPr: 'token pr',
		avatarsAI: 'ai / avatars',
		pandoraPlanet: 'PANDORA PLANET',
		communityFoundation: 'COMMUNITY FOUNDATION',
		lucaRevards: 'luca rewards',
		messagingPlatform: 'messaging PLATFORM',
		socialPlatform: 'SOCIAL PLATFORM',
		autonomyProposal: 'AUTONOMY PROPOSAL',
		proposalDecision: 'PROPOSAL & DECISION',
		usageGuide: 'usage guide',
		atmConnect: 'atm connect',
		multiverse: 'atm multiverse',
		connect: 'connect TO atm',
		faq: 'Questions & Answers',


		gameLaunch: 'game launch',
		rewards: 'rewards',
		prRank: 'atm pr rank',
		gameDev: 'funding & game dev'
	},

	homePage: {
		consensusStart: 'A meta-community ',
		consensusSpan: 'based on our new concept of relative consensus.',
		consensusEnd: 'When combined with our ATM Rank algorithm these powerful tools will make the DeFi ecosystem more stable, heathy & prosperous.',
		consensus: 'What is relative consensus?',
		rank: 'What is atm rank?',

		lucaConnections: 'Number of CC',
		lucaStaked: 'Amount staked in CC',
		totalSupply: 'Total supply',

		recoveryPlan: 'Remaining time for recovery plan',
		deflationMechanism: 'ATM Deflation Mechanism will be enabled in',
		deflationMechanismEnabled: 'ATM Deflation Mechanism has been enabled from 24/07/24',
		days: 'DAYS',
		hours: 'HOURS',
		min: 'MINUTES',
		fromDecisions: 'From isolated decisions – ',
		strengthsNumbers: 'to strengths in numbers',
		explorationStart: 'Embark on an exhilarating journey with Galaxy Explorer,',
		explorationMiddle: 'where our decentralised ATM galaxy space unveils the vast network of nodes, allowing users to seamlessly traverse and discover the unique PR values of each individual, ',
		explorationEnd: 'turning every connection into a mesmerizing constellation of possibilities.',
		exploreGalaxy: 'Click to explore ATM galaxy',
		digitalSelf: 'Create your digital-self',
		earnRewards: 'Earn rewards with your PR value',
		connectFriend: 'Connect with a friend through Consensus Contract',
		aiSupport: 'Receive AI-Powered Professional Support',
		messagingPlatform: 'Elevate engagement with Our Dynamic Messaging Platform',
		socialExperience: 'Redefining Your Social Experience',
		unleashInfluence: 'Unleash your influence',
		voiceHoldsPower: 'Your voice holds power – ',
		becomePartCommunity: 'become a part of our vibrant community, make your mark ',
		leadTheWay: ', and lead the way to a groundbreaking tomorrow. ',
		stepIntoWorldInfluence: 'Step into a world where your influence matters. Be a Key Opinion Leader (KOL) who shapes the future of ATM Innovation',
		becomeKol: 'Become KOL today',
		gamingDescription: 'Embark on an exhilarating journey into the future of gaming, where innovation knows no bounds.</br> ​</br>The gaming future transcends entertainment, blending technology, creativity, and community to craft an immersive digital landscape.',
		ecologiDescription: 'Introducing our eco-friendly initiative: With every new connection made through Ecologi, ATM is committed to planting a tree. Join us in making a positive impact on the environment while enjoying seamless and secure transactions. Switch to ATM today and watch your financial footprint transform into a greener future!',
		travelingDescription: 'Elevate your travel experience with LUCA. Through strategic partnerships with travel agencies, LUCA opens doors to a world of possibilities. </br> ​</br>Now, users can seamlessly use LUCA to explore the globe, unlocking a new era of convenience and flexibility in travel.',



		scan: 'Scan to connect with us through wallet address',
		subscribeATM: 'Subscribe ATM to receive the latest events and community updates',
		fillContactForm: 'Got any question? Simply fill-in the contact form. We would like to hear from you!',



	},

	roadMap: {
		conceptBorn: 'The concept was born',
		conceptBornDesc: 'We found that we could create a platform that uses the blockchain to connect people on a new type of social network – a relative consensus network, which we hope will be a more stable and stronger economic system.',
		smartContracts: 'Smart contracts undergo audit',
		smartContractsDesc: 'In the first steps to be verified as a beacon of trust in the cryptocurrency community ATM underwent an audit by the CertiK Cybersecurity company.',
		transitionPRNodes: 'Complete the transition of all PR nodes becoming decentralised',
		transitionPRNodesDesc: 'All resulting data from the PR calculation results will be completely decentralised and stored on the chain, making the data safer, immutable, and thus more reliable.',
		tokenPR: 'TokenPR',
		tokenPRDesc: 'The introduction of Token PR, ATM’s token launch pad.',
		avatarLaunch: 'AVATAR AI launch',
		avatarLaunchDesc: 'Our aim is to boost Luca value by intensifying our Avatar purchase mechanism to increase scarcity. Strategies will focus on gradually escalating Luca destruction aligned with Avatar popularity. We aim to empower our community, allowing users to freely generate Avatar links, engage in diverse interactions.',
		planComplete: 'Recovery plan complete',
		planCompleteDesc: 'As of October 21st, notable achievements include significant system security updates, reactivation of our rewarding system, successful minting process completion, and seamless reward retrieval.',
		endRecovery: 'End of recovery plan',
		endRecoveryDesc: 'Recovery from the previous incident.',
		upgradingWebsite: 'Upgrading website',
		upgradingWebsiteDesc: 'New fresh look of ATM website upgrades the concept visualization, animated illustrations, and multi-language support boosting user experience when surfing in ATM.',
		iOSAppLive: 'iOS App live',
		iOSAppLiveDesc: '“ATM connect” iOS App available on App Store unlocking exclusive experience in ATM ecosystem.',
		webAppRedesign: 'WebApp redesign',
		webAppRedesignDesc: 'New WebApp now equipped with a fresh interface and clear instructions on connecting to ATM through the "ATM Connect" wallet.',
		travelAgencyCollab: 'Travel Agency collaboration',
		travelAgencyCollabDesc: 'Collaboration with travel Agency to explore more real-world use case of LUCA, bringing the crypto to life.',
		AGFsmartContract: 'AGF smart contract deployment',
		AGFsmartContractDesc: 'Deployment of AGF smart contract pave the foundation for the Game Fund, allowing users to crowdfund and developers to grant.',
		AGFGameRelease: 'AGF Game release',
		AGFGameReleaseDesc: 'Launching the platform enticing more developers to join the ecosystem and enriching the gaming experience for community users.',
		deflation: 'Deflation',
		deflationDesc: 'Deflation mechanism increases LUCA’s value during price down, turning negatives into positives and stabilise LUCA’s value to safeguard ATM’s tokenomics and ecosystem.',
		airdropsDevelopment: 'Airdrops and ecosystem development',
		airdropsDevelopmentDesc: 'More opportunities to partnership and collaborate with other projects to initiate airdrops and bring other communities into ATM to foster a more inclusive and functional ecosystem.',
		january: 'January',
		october: 'October',
		february: 'February',
		q4: 'Q4',
		july: 'July',

		recovery: 'recovery',
		website: 'website',
		ios: 'iOS',
		webApp: 'web app',
		travel: 'travel',
		agf: 'AGF',
		game: 'Game',
		deflation: 'Deflation',
		airdrop: 'Airdrop',
		emptyString: '-',
	},
	prAlgorithm: {
		pageRank: 'What is PageRank?',
		pageRankDesc: 'PageRank refers to the core algorithm of Google’s search engine, and it is an algorithm for ranking the importance of webpages on the Internet. The reason why it is called “PageRank” is that such algorithm is used to rank webpages and was first proposed by Larry Page, the Google co-founder.Equivalently, we uses the consensus connection between users to replace the link between web pages in the original PageRank algorithm, and calculate the PR value of each user node in the ATM network to represent the user’s influence in the community.',
		strengthsPageRank: 'Strengths of PageRank',
		strengthsPageRankDesc: 'When Google was first established websites were ranked by traffic, not by an intelligent algorithm. This method was unstable and was open to exploitation - which meant that important websites often got lost.<br/> By looking at the relationships between websites, and not their network traffic, PageRank looks to solve this problem. The algorithm is naturally resistant to various cheating websites, and only those that are influential can be found.',
		createPRcode: 'How to create a PR node',
		createPRcodeDesc: 'Any user can build ATM’s PageRank computing server, and elect the top 11 servers with the highest stake by pledging ATM’s native currency (LUCA). These servers work together for executing the PageRank algorithm, calculate and synchronise the daily PR value of all users on the ATM network.',
		incomePRnode: 'Income from PR node',
		incomePRnodeDesc: `<p class="desc-subtitle">Operation rewards</p><br/>Users and server operators involving in the PageRank computing server stake will receive the corresponding node operation rewards.<br/>
		<p class="desc-subtitle">Stake rewards</p><br/>User with a higher pledging ratio in the server node could receive more rewards.<br/>
		<p class="desc-subtitle">Consensus rewards</p><br/>The greater the strength of the consensus connection between users, the higher the income distributed by PR computing power.`,
	},
	technologyPage: {
		unlockNewCommunication: 'Unlock a new era of communication',
		sayGoodbyeToControl: 'Say goodbye to centralised control and hello to security.',
		experienceToCommunicate: 'Experience true ownership of your data and the power to communicate ',
		withoutSurveillance: 'without fear of censorship or surveillance.',
		tryPlatformMessaging: 'Try our platform today and embrace the future of messaging!',
		systemIntegration: 'System integration',
		systemIntegrationDesc: 'Our revolutionary ATMRank algorithm, inspired by the groundbreaking PageRank, is poised to set the new standard in the industry. This algorithmic evolution isn’t just about acknowledging connections; it’s about amplifying them to unlock the true potential of cryptocurrencies.',

		systemIntegration: 'System integration',

		systemIntegrationDesc: 'Through system integration, our platform leverages the revolutionary ATMRank algorithm, inspired by the groundbreaking PageRank. The Lucy AI system and ATMRank together form the backbone of our ecosystem.<span class="space"></span><br/> Every cryptocurrency can join our platform after receiving community endorsement through a democratic vote. Once integrated, the ATMRank algorithm processes the data in real-time and writes the valuation results into the smart contract. Each cryptocurrency can also tailor its own reward system algorithm, seamlessly facilitated by Lucy. This integration ensures continuous refinement and elevation of the ATMRank system, keeping it at the forefront of crypto valuation.',


		SSsystemIntegrationDesc: `Our revolutionary ATMRank algorithm, inspired by the groundbreaking PageRank, is poised to set the new standard in the industry. This algorithmic evolution isn't just about acknowledging connections; it's about amplifying them to unlock the true potential of cryptocurrencies.<span class="space"></span><br/>
		At the core of our platform is Lucy, our state-of-the-art AI system. Together, Lucy and ATMRank form the backbone of a responsive and adaptive ecosystem that evolves in real-time with community feedback. Through the integration of cutting-edge methodologies, we continuously refine and elevate the ATMRank system, ensuring it remains at the forefront of crypto valuation.<span class="space"></span><br/>
		We welcome every cryptocurrency into our ecosystem, provided they receive community endorsement through a democratic vote. Additionally, each cryptocurrency has the opportunity to tailor its own reward system algorithm, seamlessly facilitated by Lucy.`,

		mainTechnologyTextStart: 'ATM brings a more',
		mainTechnologyTextSpan: ' stable and powerful decentralisation consensus',
		mainTechnologyTextEnd: '!',

		consensusTitle: 'What on earth is a consensus connection?',
		consensusDesc: 'ATM provides a smart contract known as a Consensus Contract, which allows users to connect with each other on the multiple public blockchains that support smart contracts',

		consensusIncomeTitle: 'Consensus connection income',
		consensusIncomeDesc: `Through consensus connection, users can receive rewards according to their PR value. The LUCA held on each public chain will be sent to a public deposit smart contract where users can withdraw at any time.<span class="space"></span><br/>
		To do so the user initiates an application, the contract initiates a request to the ATMRank computing group interface, and the PR server cluster calculates the value of rewards to be received and writes it into the contract. After the record is written, the user initiates a request to withdraw revenue, and the contract waits for confirmation from more than half of the PR server nodes. It then evaluates whether there is enough balance and processes the user's application for withdrawal.`,
		viewMyIncome: 'View my income',

		createConsensusConnection: 'How to create consensus connection',
		createConsensusConnectionDesc: 'User A sets up a consensus contract which initiates a request to establish a connection to user B. If B agrees, the contract will be executed, and the consensus connection will be successful.',
		usersNeedConfirm: 'Both users need to confirm:',

		lockedToken: "Locked Token:",
		lockedTokenDesc: "Any cryptocurrency supported by ATM;",
		investmentAmount: 'Investment amount:',
		investmentAmountDesc: 'The value that both parties agree upon - It does not have to be equal for both users;',
		lockUpTime: 'Lock-up time:',
		lockUpTimeDesc: 'The length of time for which the investment amount will be locked;',
		contractCancellation: 'Contract cancellation:',
		contractCancellationDesc: 'During the lock-up time, either suser cannot cancel the contract on their own. They must wait until the end of the contract or reach agreement with one another to terminate it early. Upon expiration, the contract will remain valid if neither party has decided to cancel.',
		viewMyConnection: 'View my connection',


		smartContracts: 'Our smart contracts',
		smartContractsDesc: `Consensus Contracts are the underlying technical feature of ATM. By using these contracts a connection can be established between users a relative consensus network can emerge.<span class="space"></span><br/>
		ATM also includes the following smart contracts:`,

		LUCAContract: 'LUCA Contract',
		LUCAContractDesc: 'This refers to the LUCA token contract. LUCA is the native token of the ATM community and also gives a user the right to vote on proposals for the project.',
		factoryContract: 'Factory Contract',
		factoryContractDesc: 'The factory contract is used to create a connection and then call related smart contracts to help users obtain the respective community incentives.',
		stakerContract: 'Stake Contract',
		stakerContractDesc: 'The stake contract is used for voting in ATMRank server operation nodes, when exercising users’ votes.',
		incentiveDistributionContract: 'Incentive Distribution Contract',
		incentiveDistributionContractDesc: 'This contract is responsible for the distribution of rewards in the ATM community such as ATMRank rewards, token stake rewards and more.',
		investmentLUCAcontract: 'Investment LUCA Contract',
		investmentLUCAcontractDesc: 'The official LUCA contract, from which 5 million tokens will be distributed. LUCA will be released by deploying this contract.',
		LUCApromotion: 'LUCA Promotion Reward Contract',
		LUCApromotionDesc: 'This is the reward contract for referrals to "Monkey", ATM’s official partner social network. This contract is called if any user invites others to join via "Monkey".',
		crossChain: 'Cross-chain Contract',
		crossChainDesc: 'Users can release cross-chain currency transfers via several public chains that are officially supported by ATM.',
		wormholeContract: 'Wormhole Contract',
		wormholeContractDesc: 'The Wormhole Contract synchronises ATM users’ information across multiple chains, in the attempt to achieve a multi-chain relative consensus network.',
		aboutOpenSource: 'Learn more about open-source code',


		auditReport: 'View audit report',
		auditReportDesc: 'Dive into our project’s detailed documentation for an in-depth look at its intricacies and strong foundation.',
		whitePaper: 'View white paper',
		whitePaperDesc: 'Explore our white paper if you’re interested in the behind the scenes and brains of the project - it outlines our aims, ambitions and technical solutions.',


		messagingPlatform: 'Messaging platform',
		messagingPlatformDesc: 'Messaging platform description',
		socialPlatform: 'Social platform',
		socialPlatformDesc: 'Social platform description',

	},
	ecosystemPage: {
		empowerment: 'Empowerment through decentralization:',
		collectiveDestiny: ' where every individual shapes our collective destiny',
		discoverGamingFund: 'Discover the ATM Gaming Fund, where community support powers innovation, with backing from our trusted VC partners, ready to spark a new era of gaming. The ATM community holds the power to vote which games come to life through their pledges. Players can earn ATM Stars and level up their gaming journey, unlocking LUCA rewards along the way.',
		developersFundingOpportunities: ' Meanwhile, developers set their funding goals and deadlines, with the chance to bring their ideas to life. By joining in, developers have the opportunity to reach a passionate player base and make their visions real.',
		learnAboutFunding: 'Learn more about funding',
		submitProject: 'Submit a project',
		ecologyContent: 'ATM proudly announces our partnership with Ecologi, a globally recognised B Corp-certified platform dedicated to accelerating climate action. Together, we are working towards a carbon-free future by actively engaging in reforestation efforts. As part of this collaboration, ATM has pledged to plant 100 trees. This commitment underscores our dedication to reducing our carbon footprint and fostering a greener planet for future generations. By connecting to Ecologi wallet through ATM, you’re not only ensuring smooth and secure transactions but also actively participating in ecosystem restoration.',
		travelRevolutionized: 'We’re revolutionizing travel experiences. Through strategic partnerships with top travel agencies, we open doors to a world of possibilities. Seamlessly integrated into everyday transactions, LUCA simplifies global exploration, offering unparalleled convenience and flexibility.',
		seamlessTravel: 'As LUCA becomes woven into daily life through strategic alliances, it’s not just about travel—it’s about enhancing every moment. Whether booking flights, hotels, or activities, LUCA is the go-to currency. Join us in embracing a future where convenience and flexibility redefine travel.',
		thirdParty: 'Calling all third-party developers and platforms!',
		joinTheATM: ' Join the ATM ecosystem community to expand your service reach.',
		utilizeOurATM: 'Utilize our ATM smart contract and Rank algorithm to attract more users and boost your project and Token.',
		embracingIntegration: 'In support of development, we’ve opted to integrate numerous third-party applications. Our open-source code is readily accessible for all developers. ATM’s vision encompasses broadening third-party options, enhancing our infrastructure to provide developers with a more robust and stable platform',
		binance: 'Binance',
		utilizingBUSD: 'The ATM community can use USDC as a trading pair of the governance token LUCA. It can also be used as the ‘lock-up token’ for consensus contract creation.',
		bitGo: 'BitGo',
		WBTCpayments: 'Users can use WBTC as a payment token when creating consensus contracts.',
		chainlink: 'Chainlink',
		chainlinkOracle: 'Chainlink will be used as an ‘oracle’ to update the prices of LUCA and other token in real time.',
		ETH: 'ETH',
		ETHissuance: 'ATM will be issued on the ETH public chain, which is also where the event will take place.',
		pancake: 'Pancake',
		stakingRewards: 'Here the community can start stake mining, where users can deposit LUCA/USDC to obtain a stable income.',
		communityEcosystem: 'Token PR is one of ATM‘s open decentralized community ecosystem. ',
		tokenIntegration: 'Third-party projects can join Token PR and add their project token to the ATM ecosystem. And by accessing the PR algorithm, they reward users who use the project Token to connect,',
		dualIncentives: ' incentivize ATM users and project users, and provide users with better ecosystem services.',
		applicationProcess: ' How to join Token PR',
		consensusRewards: 'After a third-party project joins the ATM ecosystem through a community proposal, users can use the Token of the project party to create a consensus connection, and calculate the Token PR value of the Token through the ATM PR algorithm. ATM will calculate and distribute the consensus income of the Token to the user according to the Token PR value and the reward distribution proportion of the project party.',
		rewardModes: 'Reward modes for ATM projects',
		tapNumbers: 'Tap numbers to view 3 reward modes',
		learnRewards: 'Learn more about rewards',
		joinNow: 'Join now',
		automaticDistribution: 'All Token rewards are overall distribution without the need to calculate Token PR. Rewards are distributed according to the main PR weight;',
		dualDistribution: 'Token rewards consists of overall distribution and independent distribution. For example, the overall rewards account for 40% and the independent rewards account for 60%. Token PR needs to be calculated. The overall rewards are distributed based on the main PR weight and the independent rewards are distributed based on the Token PR weight;',
		tokenBased: 'Token rewards are all independent distribution and need to calculate Token PR. Rewards are distributed according to the Token PR weight.',

		rewardExample: 'Reward example',
		rewardDistribution: 'The third-party token, COOL, connects the ATM ecosystem and presents one million tokens to distribute rewards for 100 days and 10,000 tokens per day.',
		dualRewards: 'The reward distribution proportion: 60% for overall rewards and 40% for independent rewards.<br/>When users use COOL to create a consensus, they will receive rewards from both main PR calculation and the Token PR calculation.',
		sharedAllocation: 'In other words, users of the entire network share 6,000 COOL tokens per day based on the main PR weight, and users who use COOL to create consensus connections share 4,000 COOL tokens per day based on the Token PR weight.',
		IveUnderstood: 'I‘ve understood',
	},
	communityPage: {
		empowerConnectivity: 'Unleash the power of connection:',
		decentralizedEngagement: ' redefining community interactions through decentralisation',
		hiLucy: 'Say hi to Lucy!',
		meetLucy: 'Meet Lucy, your next-generation conversational agent, expertly trained to respond to your queries with ease. Curious about LUCA, ATM-Rank, or Consensus Connections?<br/><br/>Lucy has you covered. With advanced natural language processing, she recognises your intent and delivers accurate information. Experience the convenience of Lucy as she expertly guides you through ATM.',
		chatLUCY: 'Chat with LUCY',
		transformingInteractions: 'In our ATM community, we‘re transforming the way we interact with AI. Imagine a world where humans and AI coexist seamlessly,<span class="bold-text"> blurring boundaries and enriching every interaction. Our innovative AI technology brings this vision to life, creating a vibrant and dynamic community where the possibilities are endless.<span/>',
		meetTeam: 'Meet the team',
		unlockExpertise: 'A financial expert team for Researchers, Journalists, and Business Consultants. Whether exploring, working on projects, or making critical decisions, our AI agents assist you every step of the way.<br/><br/>The Innovation Oracle offers a comprehensive, personalised overview. Experience the power of our AI agents today and unlock new possibilities!',
		chatWithExperts: 'Chat with our experts',
		autonomousGovernance: 'The community foundation belongs to all users. Its <span class="bold-text">accounts are fully autonomous, and the private key of the foundation wallet is jointly held by the directors of the foundation.</span><br/><br/> The fund management board is composed of the 47 users with the highest PR value in the community. The board of directors has executive power, but  <span class="bold-text"> the final decision always belongs to all members of the community</span> via voting on proposals.<br/><br/>  <span class="bold-text">Funds require approval by over half of the board of directors.</span> Inactive members are replaced by the algorithm, with the next highest PR user taking their place as director.',
		earnLUCA: 'Earn LUCA rewards through consensus connections and staking with LUCA, the native cryptocurrency of ATM. <span class="bold-text">Dive into our rewarding ecosystem where 85% of daily issuance is dedicated to consensus connection rewards, distributed based on your PR values. The remaining 15% is for staking rewards, with top users qualifying for issuance.</span><br/><br/> Stake LUCA directly or via consensus contracts to boost network security and reap rewards.  <span class="bold-text">LUCA serves multiple purposes, from rewarding consensus connections to facilitating seamless transactions.</span> Remember, the higher your PR, the greater your LUCA rewards!',
		engageDynamically: 'Our messaging platform fosters vibrant discussions within the ATM community. Whether you‘re a seasoned enthusiast or a newcomer, connect, share insights, and stay updated on trends. Create custom chatrooms, join existing ones, or engage in direct messaging. Stay informed, exchange ideas, and build connections.',
		seamlessInteractions: 'Seamless interactions',
		discoverConnectEngage: 'Discover, Connect, Engage',
		connectInformatively: 'Our platform is a dynamic hub for members to share news, posts, and insights, fostering engaging discussions on topics relevant to the ATM ecosystem. Stay updated, interact with enthusiasts, and forge connections in a seamless, intuitive space. Customise your experience with interactive tools for a personalised social experience.',
		communityGovernance: 'ATM is a decentralized distribution mechanism. This means that users need to manage it as a community to allow it to continuously evolve and stay relevant. <span class="bold-text">We hope that this should offer stability when the economic environment changes unpredictably around us.</span> In short, the community manages the ATM functions, algorithms and technical architectures by voting on proposed changes.',
		conditionalApproval: 'Different proposals are passed under different conditions',
		viewConditions: 'Tap numbers to view the conditions',
		whatAvatar: 'What is AVATAR?',
		consciousnessExtension: 'AVATARS redefine digital presence by extending your consciousness and enhancing your abilities in the virtual realm. They go beyond mere assistants, representing the various facets of your life - from professional to personal spheres.',
		craftAvatar: 'Craft your AVATAR',
		creationEmpowered: 'With Avatar, the power of creation is in your hands. Simply write a prompt, and watch as your vision comes to life before your eyes. Whether you envision a sleek professional or a whimsical adventurer, Avatar can embody any role you desire, bringing your digital dreams to fruition.',
		trainAvatar: 'Train it to be like you',
		dynamicEvolution: 'Your Avatar isn‘t just a static creation; it‘s a dynamic entity that evolves alongside you. Through personalized training sessions, you can upload documents and engage in Q&A sessions to refine your Avatar‘s consciousness. Watch as it learns from your behaviors, interests, and preferences, becoming an accurate reflection of your digital self.',
		shareAvatar: 'Share your AVATAR with friends',
		avatarNetworking: 'Once you‘ve crafted your perfect Avatar, it‘s time to share the magic with your friends. With just a click, you can generate an AVATAR link and invite others to interact with your digital creation. Whether it‘s introducing your Avatar to your social circle or connecting with friends through their Avatars, the possibilities for meaningful interaction are boundless.',
		discoverDigitalSelf: 'Discover your digital-self with',
		agt: 'AGT',
		fullNameofAGT: 'The full name of AGT is ATM Governance Token.',
		receiveAGTdistributed: 'After users created a consensus connection with LUCA, they can receive AGT distributed by the ATM',


		dataNoLoaded: 'The data you are looking for hasn‘t loaded yet',
		checkBackShortly: 'Please check back shortly or try refreshing the page. We apologise for the inconvenience.',
		underReview: `Under review`,
		successfulAudit: `Successful audit`,
		auditFailed: `Failed audit`,
		inProgress: `In progress`,
		ended: `Ended`,
		failure: `Failure`,
	},
	newsPage: {
		stayAhead: 'Stay Ahead of the Curve:',
		innovationNews: ' Dive into the Latest Tech & Innovation News',
		notice: 'notice',
		hotNews: 'hot News',
		copyLink:"Copy link",
		linkCopied:"Link copied! Now go ahead and share it!"
	},
	helpPage: {
		wealthOfResources: 'Dive into our wealth of resources and emerge prepared to',
		conquerTheIntricacies: ' conquer the intricacies of ATM with unwavering confidence and expertise',
		understandingTechnology: "If you are a new user of ATM and want to get a better understanding of the technology, we offer a series of tutorials on our core applications and functions. From the basic concepts and white paper explanations to more in-depth application operations, our goal is to give you a more comprehensive understanding of ATM.",
		ATMcourse: 'ATM Course',
		courseContains: 'This course contains beginner tutorials covering the basic sectors of the ATM platform.',
		connectMetaMask: 'Connect MetaMask wallet',
		participateInPR: 'How to participate in PR node stake and election',
		howAddLiquidity: 'How to add LUCA/USDC liquidity',
		createConsensusConnection: 'Create a consensus connection',
		queryConnectionByAddress: 'Query consensus connection by user address',
		checkIncome: 'How to check and receive total income',
		purchaseLUCA: 'How to purchase LUCA',
		initiateCommunityProposal: 'How to initiate a community proposal',
		seamlessConnectivity: 'Unlock the ultimate convenience with the <span class="bold-text">ATM Connect app – your go-to solution for seamless connectivity on the go! Seamlessly connect</span> with friends, access your wallet, keep track of your connections, and dive into your personalised dashboard, all from the palm of your hand.',
		getConnent: 'Download the ATM Connect app now!',
		supportedChains: 'Supported public chains',
		upcomingChains: 'Upcoming public chains',
		installingWallet: 'Installing your wallet*',
		connectingATM: 'Connecting with ATM',
		fundingWallet: 'Funding your wallet',
		needConnectedWallet: 'To use ATM you need to have a wallet that is connected to the Binance Smart Chain.',
		downLoadMetaMask: '1. Click the ‘MetaMask’ button below to download and install, or to sign in if you already have it.',
		followMetaMaskSetup: '2. Follow the MetaMask setup until completion.',
		getMetaMask: 'Get MetaMask',
		metaMask: 'MetaMask',
		onceCompleteClickButton: '3. Once complete, click the ‘Add BSC network’ button we have provided here to automatically switch you over.',
		addBSCnetwork: 'Add BSC network',
		reopenWebpage: '4. If you`re on mobile, reopen this webpage via the MetaMask app. (Menu -> Browser)',
		recommendAddCurrencies: '5. We also recommend that you add the currencies LUCA, USDC & AGT to your wallet. You can do so by clicking the buttons below.',
		clickConnect: 'Click ‘Connect ATM’ and sign the message - this will link your wallet with the website.',
		totallySecure: 'Don`t worry, it`s totally secure - it just lets us know that you`re really the owner of the wallet!',
		dashboardHasInformation: '• Your dashboard has all the information you`ll need to get going with ATM.',
		beginBuildingNetwork: '• After looking around the dashboard click `Create Connection` to begin building your social network!',
		beginYourJourney: 'Well, that`s it! Click the button below and sign the message to begin your journey with ATM:',
		gotNewWallet: 'If you`ve got a totally new wallet, you`re going to need to fund it to make use of the Binance Smart Chain, which uses BNB as a transaction processing fee.',
		canBuyBNB: 'You can buy BNB directly through MetaMask by making use of the third-party payment options: MoonPay & Transak.',
		buyWithMoonpay: 'Buy BNB with MoonPay',
		supportsPaymentMethods: 'MoonPay supports popular payment methods, including Visa, Mastercard, Apple / Google / Samsung Pay, and bank transfers in 145+ countries. Tokens deposit into your MetaMask account.',
		buyFromBinance: 'You can also buy from Binance and send it to your wallet separately (Which we recommended for more advanced crypto users)',
		swapToLuca: 'Once you`ve secured some BNB, you can then swap to LUCA it through MetaMask, which can then be staked in the network. You can also purchase LUCA via Pancakeswap if you are a more advanced user.',
		metaMaskSuggestedWallet: '*MetaMask and the Binance Smart Chain are third party and not the products of ATM. MetaMask is our suggested wallet, but alternatives are available. Use at your own discretion.'
	},

	faq: {
		selectCategory: 'Select a category from the left to view FAQs.',
		problemTitle: ['ATM', 'LUCA', 'Community', 'Governance'],
		qaZhili: [
			{
				question: 'What is AGT used for?',
				answer: 'The community proposal function can be implemented by the community governance token AGT. Users who hold the AGT hold voting rights.'
			},
			{
				question: 'What’s the difference between common proposals and special proposals?',
				answer: '(1) In terms of common proposals, community members can vote YES/NO and the proposal will be valid if the total votes exceed 2% of the total AGT circulation. If the proposal obtains more than 2/3 YES, it is approved and implementation starts. Otherwise, the proposal is failed and will not be implemented. (2) For special proposals, the proposal initiator shall publicize the entry-into-force conditions and implementation of the proposal in the community in advance. After the publicity period ends, the proposal flow continues. If there is any objection throughout the publicity period, the initiator need to change the conditions and publicize it again.'
			},
			{
				question: 'How to obtain AGT?',
				answer: 'After users created a consensus connection with LUCA, they can receive AGT distributed by the ATM community.'
			},
			{
				question: 'Is AGT the only governance token for the community?',
				answer: 'AGT is the only governance token of the ATM community.'
			},
			{
				question: 'Can I get AGT back after the proposal?',
				answer: 'After each proposal voting ends, community users can redeem AGT for voting on other proposals.'
			},
			{
				question: 'How many AGTs do I need to exercise community governance rights?',
				answer: 'At least 1 AGT.'
			},
			{
				question: 'How to initiate a proposal?',
				answer: 'By far, the proposal can only be initiated by the ATM. If you need to initiate a proposal, please email to the ATM official (autonomoustrustmomentum@gmail.com) to make an application. The proposal initiating will be progressively opened in the later stage.'
			},
		],
		qaATM: [
			{
				question: 'What is ATM?',
				answer: 'ATM is a set of decentralized mechanisms based on multiple blockchains, from which the relative consensus network can emerge.'
			},

			{
				question: 'Who can use ATM?',
				answer: 'Anyone that is using a MetaMask wallet and has joined the BSC network.'
			},
			{
				question: 'Is ATM open source? ',
				answer: 'Yes. You can find out more <a  target="_blank" href="https://github.com/ATM-Developer/atm-contract" class="link">here</a>.'
			},
			{
				question: 'What is ATM composed of?',
				answer: 'The main components of ATM are the consensus contract, ATM Rank algorithm, LUCA issuance algorithm and community voting mechanisms.'
			},


			{
				question: 'How do I create a Consensus Connection?',
				answer: 'Go to the “Consensus Connection” page and click the “Create Consensus” button. Fill in the requested information and then submit! For more details, follow the “Create a Consensus Connection” guide located <a  target="_blank" href="https://www.atmrank.com/pdf/atm-en/4.pdf" class="link">here</a>.'
			},
			{
				question: 'How do I cancel a connection?',
				answer: 'Go to the “Consensus Connection - Connected” page and navigate to “View Details” and then “Agree to Disconnect”. For more details look for step 6 in our guide located <a  target="_blank" href="https://www.atmrank.com/pdf/atm-en/4.pdf" class="link">here</a>.'
			},
			{
				question: 'How many connections can I create?',
				answer: 'It’s unlimited! There’s no upper limit of the number of connections that you can establish.'
			},
			{
				question: 'Which public blockchains does ATM currently support? ',
				answer: 'At this moment in time only the Binance network is supported, but we will support the following in the future: Matic, OKExChain, Theta & Ethereum. '
			},
			{
				question: 'Are those the only blockchains that will be supported?',
				answer: 'No! Support for new blockchains can be added depending on the will of the community. By voting for proposals, you can decide the next blockchain that ATM incorporates. '
			},
			{
				question: 'Is ATM a pyramid scheme or MLS ?',
				answer: `Well, sorry to dissapoint the more sceptical among you but no, it's not! <br>
				The ATM algorithm, cleverly named ATMRank, is based on Google's PageRank. The resemblance is not just in name but also in code - you can see for yourself, it's open source. Would you say that Google's
				PageRank is a pyramid scheme? 🙂<p class="split"> </p>
				
				ATM's rewards are entirely dependent on your influence as an influencer (sorry, don't have a thesaurus handy...) - While the early adopters have been rewarded significantly, it does not mean that they are benefitting from your arrival to the network as would be the case in a classic pyramid scheme.
				<p class="split"> </p>
				The reward mechanism is a zero-sum game, meaning that that you can only grow your share of the pie but the pie itself never grows - hardly a good deal for those pesky users at the top of the pyramid, right!?<br>
				And don't forget, you don't even need an invite to join ATM - that's the opposite of a pyramid scheme!`
			},

			{
				question: ' Where to find and download the ATM mobile app? ',
				answer: 'To download the ATM mobile app, simply go to your device’s app store (iOS or Google Play Store). In the search bar, type in "ATM connect" to locate the app. Once you find it, follow the on-screen instructions to download and install it on your device. '
			},
			{
				question: 'How to connect with MetaMask wallet? ',
				answer: 'To connect to your MetaMask wallet, search for "MetaMask" on Google Chrome and download it from the official website by clicking the [Download] button. Add MetaMask to Chrome, then click "Web app" to sign in to ATM with ease using your MetaMask wallet. Happy connecting!<br> To connect to ATM, ensure your wallet is linked to the Binance Smart Chain. Install MetaMask, process to sign in to ATM with MetaMask, and add the ’BSC network’  chain. Enhance your experience by adding LUCA, USDC & AGT to your wallet. You can access wallet at anytime by connecting to ‘Web app’. For more convenience, download the ATM.Connect app.'
			},

		],
		qaLuca: [
			{
				question: 'How can I get LUCA?',
				answer: '1.Participate in the initial LUCA event; 2. Provide liquidity and earn rewards for the LUCA/USDC transaction pair on Pancakeswap; 3. From establishing Consensus Connections; 4. From stake mining.'
			},
			{
				question: 'Where can I buy LUCA?',
				answer: 'You can’t! After the LUCA event is over, LUCA can be converted using the Pancakeswap platform, or earned using the methods mentioned in the previous question. '
			},
			{
				question: 'How many LUCA will be issued?',
				answer: 'There will be an initial issuance of 15 million tokens, starting from ATM’s official launch date, 36000 LUCAs will be issued daily.  After 1,000 days the deflationary mechanism will be enabled.'
			},

			{
				question: 'What are the benefits of owning LUCA?',
				answer: 'By owning LUCA you have the right to vote on proposals put forward by the community, ensuring your voice is heard! '
			},
			{
				question: 'What’s that ‘Deflationary Mechanism’ you mentioned?',
				answer: 'After 1000 days from the initial LUCA event, the deflationary mechanism will be initialised. This process will check the percentage that LUCA drops compared with the average price of the previous day and then automatically lower the LUCA issuance by the same percentage.\n' +
					'Note that LUCA locked in Consensus Contracts will not be affected by the deflation!'
			},
			{
				question: 'How do I become a node staker?',
				answer: 'Navigate to the “Consensus Connection-PR Node” page and select a node. Click on “Stake” and enter the amount before confirming. You can follow our more in-depth guide  <a  target="_blank" href="https://www.atmrank.com/pdf/atm-en/1.pdf" class="link">here</a> for more details.'
			},
			{
				question: 'Where can I view my stake income?',
				answer: 'Go to the “Consensus Connection – Gross Income” page.'
			},
			{
				question: 'How to convert an Ethereum Mainnet token to the Binance Smart Chain?',
				answer: 'The ability to transfer tokens cross-chain is an essential need, it allows users to transfer their funds from one blockchain network to another. Multiple networks now have their respective “bridges” to help in easy fund transfers. The following is a list of all cross-chain apps supporting the binance smart chain, the majority of which supply a tutorial to help you use them.<br>  <a  target="_blank" href="https://docs.binance.org/smart-chain/guides/cross-chain.html" class="link">https://docs.binance.org/smart-chain/guides/cross-chain.html</a>'
			},
		],
		qaCommity: [
			{
				question: 'How can I keep track of proposals?',
				answer: 'You can see this on our Community Autonomy page located <a  target="_blank" href="https://www.atm.network/#/community" class="link">here</a>!'
			},
			{
				question: 'What is the board? What does it do?',
				answer: 'The Board is made up of the 47 users that have the highest PR value in the community, who jointly hold the secret key of the community fund. If a member of the board is inactive for a prolonged period, they will be replaced by the user with the next highest PR value.'
			},
			{
				question: 'What is the role of the Board?',
				answer: 'The Board members jointly keep the wallet secret key of the Fund, and fund can only be used upon signature of more than half of the members. The Board exercises the execution and operation rights.'
			},
			{
				question: 'How do I stay up to date with ATM?',
				answer: 'View our News page <a  target="_blank" href="https://www.atm.network/#/notice" class="link">here</a>!'
			},

			{
				question: 'How to contact us?',
				answer: 'Users can send an email to: autonomoustrustmomentum@gmail.com.'
			},
		],

	},
	gamingPage: {
		gamingPageDescription: 'We have decided to start a game fund to <span class="bold-text">support game developers and to have our own ATM game. Every ATM user will be able to play, have fun and earn rewards. Game developers will need to set their funding goals and deadlines</span> (e.g., set a date for the game`s release). If the ATM users like the game, they can pledge money to have the game created.',
		usersWillEarnStars: 'By playing games, ATM users will earn ATM Stars and increase their game`s level, which will help them earn more <span  style="color: #0DAEB9; font-weight: 700;">LUCA</span> rewards. ',
		gameLaunchDescription: 'As gamers connect with the game, their PR soars to new heights. Meanwhile, the <span class="bold-text">game wallet accumulates daily PR income from user connections</span>. Every bit of the daily ATM PR will be directed to the ATM game funds, <span class="bold-text">guaranteeing full refunds for ATM investors</span>.</br></br> Our system ensures a standardised connection between users and their game wallet, based solely on their contribution amount and the duration of connection days. Users <span class="bold-text">PR value will be influenced by their accumulation of ATM stars and their progression in the game levels. This transparent approach ensures fairness and consistency for all players</span>, enhancing their gaming experience.',
		fundsFullyRefunded: 'When the funds are fully refunded,',
		usersInvestors: '70% will go to the users (players), and 30% will go to the ATM investors',
		dailyFromGames: ' daily from all games.',
		howGetStars: 'How to get ATM stars',
		creatingConnectionWithGame: 'The users will get ATM stars by creating a connection with the game 100% (for example, 100LUCA for 90 days);',
		winningBattle: 'By winning the battle. If a player loses the battle, they lose the ATM stars (the amount which they put in a battle);',
		unlockFeaturesWithStars: '<span class="bold-text-white">Unlock exciting features with ATM stars! Purchase tickets for exclusive battles, NFT costumes, and items</span>. When disconnected from the game, the game wallet will automatically deduct stars from your account, ensuring fairness and balance. If your star balance is not sufficient, it will be reset to zero, giving everyone a fresh start.',
		getRewardsBy: 'ATM users will get rewards by:',
		connectionsWithGames: 'Creating connections with games',
		playingGames: 'Playing the games',
		investingInGames: 'Investing in games',
		receivingRewards: 'Receiving daily rewards from the Star pool',
		performanceRanking: 'The PR value will depend on the player`s ATM game level and ATM stars. There will be the normalised ATM XP, that will be used in ATMRank.',
		atmXP: 'PR = PR × normalised [ATM XP]',
		atmGameLevel: 'ATM XP = ATM stars + normalised [ATM game level]',

		previewVideo: 'Click on play button to preview the video',
		crowdfundingPitch: 'The developer shares their game concept, providing a description, possibly including images, specifying the required funds, and setting a deadline for reaching the funding goal.',
		investPreferredGames: 'Everyone in the ATM community can invest (not only with LUCA, with others as well) in their preferred games.',
		fundsReturnedToInvestors: 'If the funds haven`t reached the goal, the offer will be rejected, and the funds will be returned to investors.',
		fundsReachedGoal: 'If funds have reached the goal, they go to the developer. '
	},
	explorerPage: {
		discoverPower: 'Discover the Power of LUCA:',
		buildingStability: ' Building Stability through Consensus',
		overview: 'Overview',
		galaxy: 'Galaxy',
		overallRank: 'Overall Ranking',
		consensusConnection: 'Consensus Connection',
		prNode: 'PR Node',
		stakeTransaction: 'Stake Transaction',
		userInformation: 'User Information',
		contractInformation: 'Contract Information',
		price: 'LUCA Price',
		totalSupply: ' Total Supply',
		marketCirculation: 'User market circulation',
		circulatingSupply: 'Circulating supply',
		liquidityRewards: 'Remaining liquidity rewards',
		stakedConnections: 'LUCA staked in Consensus Connections',
		stakedPRservers: 'LUCA staked in PR servers',
		remainingFund: 'Remaining Community Fund',
		LUCAConsesusConnections: 'LUCA Consesus Connections',
		prServersOperation: 'PR Servers in Operation',
		LUCAoverview: 'LUCA Overview',
		proportionOfLuca: 'Proportion of Luca issuance in the entire network',
		statistics: 'Statistics',
		more: 'More',
		less: 'Less',
		currencyStaked: `{currency} staked in Consensus Connections `,
		numberOfCurrency: `Number of {currency} Connections`,
		currencyContractAddress: `{currency} contract address`,
		currencyPriceTrend: `{currency} price trend`,
		price: 'Price',
		noData: "Data currently unavailable",
		rankingDataUpdatedDaily: `Ranking data is updated daily. The latest update:`,
		ranking: 'Ranking',
		userAddress: 'User Address',
		PRvalue: 'PR value',
		network: 'Network',
		currency: 'Currency',
		connectionContractInfo: 'Connection contract information',
		initiator: 'Initiator',
		hash: 'Hash',
		receiver: 'Receiver',
		connectionQuantity: 'Connection quantity',
		time: 'Time',
		action: 'Action',
		more: 'More',
		PRnode: 'PR node',
		stakeAmount: 'Stake amount/limit',
		stakeTransactionInfo: `Stake transaction information`,
		PRnodeInfo: `PR node information`,
		serverName: 'Server domain name',
		serverIP: 'Server IP',
		serverNickname: 'Server nickname',
		stakeAmount: 'Stake amount',
		status: `Status`,
		statusList: [
			"Initiate a connection",
			"Connecting",
			"Initiator canceled",
			"Target user rejected",
			"Maturity redemption untransferred",
			"Maturity redemption transferred",
			"Early redemption untransferred",
			"Early redemption transferred",
		],
		creationTime: `Creation Time`,
		connectionQuantity: `Connection quantity`,
		NFTproject:'NFT project',
		NFThedging:'NFT Hedging',
		connectionContract: `Connection Contract`,
		lockTime: `Lock Time`,
		days:'days',
		transactions:'Transactions',
		contractBalance:'Contract account balance',
		lastBalanceUpdate:'Last Balance Update: Block #',
		viewAll: `View all`,
		viewSourceCode: `View source code`,
		viewConnections: `View all connections`,
		viewTransactions: `View all transactions`,
		PRnodeDetails: `PR node details`,
		number: `{number}`,
		nodeRanking: `Node ranking`,
		nodeStakeDetails: `Node stake transaction details`,
		stakeMethod: `Stake method`,	
		LUCAstake: `LUCA stake`,
		consensusContract: `Consensus contract`,
		stakeNode: `Stake node`,
		search:'Search...'
	},
	travelPage:{
		sharedVision:'A shared vision',
		offerSeamless:'We aim to offer a <span class="bold-text">seamless and efficient solution for booking hotels, providing highly competitive rates</span> sourced from a wide network of trusted providers that benefits ATM community ',
		bookHolidayWithLuca:'Book your next holiday  <span class="gradient-text">getaway with ATM</span> ',
		flights:'flights',
		hotels:'Hotels',
		optimizeJourneys:'<span class="gradient-text">Enhance your travel experience</span> with ATM',
		flightThroughATM:"Book your flight through",
		effortlessBooking:'Easily find and book flights through our partner’s page by clicking the "Book Flight" button below. You can also exchange your LUCA for USDC, making your ticket purchase even more convenient.',
		bookHotelThroughATM:'Book your hotel through',
		hotelExclusives:'Find exclusive hotel deals for your trip! Click ‘Book hotel’ to sign up with your email and explore all the hottest deals!',
		saveUpTo:'Save up to 60%',
		yourNextHotelBooking:'on your next hotel booking',
		bookHotel:'Book hotel',
		with:'with',
		saveUpTo25:'Save up to 25%',
		yourNextFlight:'on your next flight booking',
		bookFlight:"Book flight",
		upTo:'Up to',
		bookingsMade:'Bookings made',
		amountLUCAburnt:'Amount of LUCA burnt',
		totalAmountSaved:'Total amount saved',
		infoTooltipText:'Track the total amount burned in real time! Every time you book a trip, part of your payment burns LUCA, permanently reducing supply and strengthening its value. This process keeps LUCA’s economy stable and sustainable.',
		smartContract:'Smart contract',
		usersSavingBooking:"Users' saving by booking with LUCA Travel compared to other platform."
	}

}
