import { formatEther } from 'viem';
// parse to eth
export function convertToEth(value) {

    // convert value to string 
    let gwei = value.toString();
    //  parse from wei to eth 
    // only two decimal places

    let x = formatEther(gwei).toString();
    return Number.parseFloat(x).toFixed(2);
    // return new BigNumber(value).multipliedBy(new BigNumber(10).pow(18)).toString();

}