<template>
    <div class="container">
        <div v-if="title" class="section-title">
            {{ title }}
        </div>
        <div class="section-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Section',
    props: {
        title: {
            type: String,
            required: false
        }
    }
});
</script>