<template>
    <div class="pagination-slider">
        <swiper
            :style="{ height: currentHeight }"
            class="swiper"
            :modules="modules"
            :slides-per-view="slidesPerView"
            :slides-per-group="slidesPerGroup"
            :centered-slides="centeredSlides"
            :navigation="navigation"
            :pagination="paginationOptions"
            :autoplay="autoplayOptions"
            :loop="loop"
            ref="swiper"
            :speed="speed"
            :effect="effect"
        >
            <swiper-slide v-for="(slide, index) in normalizedSlides" :key="index">
                <slot name="slide" :slide="slide" />
            </swiper-slide>
        </swiper>

        <div v-if="paginationOutside" :id="paginationId" class="pagination-outside">
            <slot name="pagination" />
        </div>
    </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

export default defineComponent({
    name: 'PaginationSlider',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        slides: {
            type: Array,
            required: true,
        },
        effect: {
            type: String,
            default: 'slide',
        },
        slidesPerView: {
            type: Number,
            default: 1,
        },
        slidesPerGroup: {
            type: Number,
            default: 1,
        },
        centeredSlides: {
            type: Boolean,
            default: false,
        },
        navigation: {
            type: Boolean,
            default: false,
        },
        paginationOutside: {
            type: Boolean,
            default: false,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
        autoplayDelay: {
            type: Number,
            default: 1000,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        speed: {
            type: Number,
            default: 3000,
        },
        paginationId: {
            type: String,
            required: true,
        },
        desktopHeight: {
            type: String,
            default: '450px',
        },
        tabletHeight: {
            type: String,
            default: '350px',
        },
        mobileHeight: {
            type: String,
            default: '250px',
        },
    },
    setup(props) {
        const currentHeight = ref(props.desktopHeight);

        const updateHeight = () => {
            const width = window.innerWidth;
            if (width <= 550) {
                currentHeight.value = props.mobileHeight;
            } else if (width <= 1024) {
                currentHeight.value = props.tabletHeight;
            } else {
                currentHeight.value = props.desktopHeight;
            }
        };

        onMounted(() => {
            updateHeight();
            window.addEventListener('resize', updateHeight);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateHeight);
        });

        const normalizedSlides = computed(() =>
            props.slides.length < 2 ? [...props.slides, ...props.slides] : props.slides
        );

        const autoplayOptions = computed(() =>
            props.autoplay
                ? { delay: props.autoplayDelay, disableOnInteraction: false }
                : false
        );

        const paginationOptions = computed(() => ({
            clickable: true,
            el: `#${props.paginationId}`,
        }));

        return {
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            normalizedSlides,
            autoplayOptions,
            paginationOptions,
            currentHeight,
        };
    },
});
</script>

<style lang="scss" scoped>
.pagination-slider {
    position: relative;
    width: 100%;

    .swiper {
        width: 100%;
        height: var(--slide-height);

       
    }


    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .pagination-outside {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        gap: 5px;
    }
}
@media (max-width: 900px) {
    .swiper {
        height: var(--slide-height-mobile);
    }
}
</style>