<template>
    <Section :title="$t('title.proposalDecision')">
        <div class="proposal-section">
            <div class="proposal-container">
                <div class="proposal-content" v-for="(item, index) in initiateList" :key="index">
                    <div class="proposal-title">
                        <h4>{{ item.title }}</h4>
                    </div>
                    <div class="proposal-text">
                        <p>{{ item.content }}</p>
                    </div>
                    <div class="proposal-date" v-if="item.startTime && item.endTime">
                        <p>{{ formatDateTime(item.startTime) }} - {{ formatDateTime(item.endTime) }}</p>
                    </div>
                    <div class="proposal-buttons" v-if="item.status !== undefined">
                        <div class="status-wrapper">
                            <p>{{ btnText(item.status) }}</p>
                        </div>

                        <div @click.prevent="handleViewClick(item.id)" class="view-btn">
                            <p>{{ $t('view') }}</p>
                            <img src="@/assets/images/icons/array-right.svg" alt="array-right">
                        </div>

                    </div>
                </div>
                <div class="proposal-img">
                    <img src="@/assets/images/communityPageImg/hands-img.svg" alt="">
                </div>
            </div>
            <div @click.prevent="handleViewAllClick" class="origin-btn">
                <p>{{ $t('viewAll') }}</p>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '@/components/common/section.vue';
import { getInitiateList } from '@/api/api';

export default defineComponent({
    name: 'ProposalDecisionSection',
    components: {
        Section,
    },
    props: {
        web3Model: Object,
        authStore: Object,
        popUp: Function,
    },
    data() {
        return {
            initiateList: [],
            token: localStorage.getItem("token"),
        };
    },

    computed: {
        hasToken() {
            return this.token !== null;
        },
    },

    mounted() {
        window.addEventListener("storage", this.updateTokenFromStorage);
        this.checkTokenInterval = setInterval(this.updateTokenFromStorage, 1000);
    },
    beforeDestroy() {
        window.removeEventListener("storage", this.updateTokenFromStorage);
        clearInterval(this.checkTokenInterval);
    },

    methods: {
        updateTokenFromStorage() {
            const token = localStorage.getItem("token");
            if (this.token !== token) {
                this.token = token;
            }
        },
        handleViewClick(id) {
            if (this.hasToken) {
                window.location.href = `/v1/#/proposalDetails?id=${id}`;
            } else {
                this.onClickConnect();
                this.redirectAfterLogin(`/v1/#/proposalDetails?id=${id}`);
            }
        },
        handleViewAllClick() {
            if (this.hasToken) {
                window.location.href = `/v1/#/communityProposal`;
            } else {
                this.onClickConnect();
                this.redirectAfterLogin(`/v1/#/communityProposal`);
            }
        },
        onClickConnect() {

            if (this.web3Model && typeof this.web3Model.open === "function") {
                this.web3Model.open();
            } else {
                console.error("error: web3Model  not loaded, open() not a function");
            }

            if (this.popUp && typeof this.popUp === "function") {
                this.popUp();
            }
        },

        redirectAfterLogin(redirectUrl) {
          
            const checkToken = setInterval(() => {
                const token = localStorage.getItem("token");
                if (token) {
                    clearInterval(checkToken);
               
                    window.location.href = redirectUrl;
                }
            }, 500);
        },

        btnText(type) {
            switch (type) {
                case 1: return this.$t('communityPage.underReview');
                case 2: return this.$t('communityPage.successfulAudit');
                case 3: return this.$t('communityPage.auditFailed');
                case 4: return this.$t('communityPage.inProgress');
                case 5: return this.$t('communityPage.ended');
                case 6: return this.$t('communityPage.text8');
                default: return '';
            }
        },
        async fetchInitiateList() {
            try {
                const response = await getInitiateList();
                if (response.success) {
                    this.initiateList = response.data.initiateList;
                } else {
                    console.error('API request failed');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            this.showIfNoData();
        },
        showIfNoData() {
            if (this.initiateList.length === 0) {
                this.initiateList.push({
                    title: this.$t('communityPage.dataNoLoaded'),
                    content: this.$t('communityPage.checkBackShortly'),
                });
            }
        },
        formatDateTime(timestamp) {
            const date = new Date(timestamp * 1000);
            return date.toLocaleString();
        }
    },
    created() {
        this.fetchInitiateList();
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/communityPage/proposalDecision.scss';
</style>
