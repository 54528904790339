<template>
    <div class="community-page">

        <FirstScreen :imageSrc="require('@/assets/images/communityPageImg/community-main.webp')"
            :imageSrcMobile="require('@/assets/images/communityPageImg/community-main-mob.webp')"
            :titleSpan="$t('communityPage.empowerConnectivity')"
            :titleEnd="$t('communityPage.decentralizedEngagement')" />
        <AvatarsSection />
        <PandoraSection />
        <RewardsSection />
        <MessagingPlatformsSection />
        <SocialPlatformSection />
        <AutonomyProposalSection />
        <ProposalDecisionSection  :web3Model =web3Model :authStore = authStore />

        <CommunityFoundationSection />
        <DownloadSection />

    </div>
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import AvatarsSection from '../components/communityPage/avatarsSection.vue';
import PandoraSection from '../components/communityPage/pandoraSection.vue';
import CommunityFoundationSection from '../components/communityPage/foundationSection.vue';
import RewardsSection from '../components/communityPage/rewardsSection.vue';
import MessagingPlatformsSection from '../components/communityPage/messagingPlatforms.vue';
import SocialPlatformSection from '../components/communityPage/socialPlatform.vue';
import AutonomyProposalSection from '../components/communityPage/autonimyProposal.vue';
import ProposalDecisionSection from '../components/communityPage/proposalDecision.vue';
export default {
    name: 'CommunityPage',
    props: {
        web3Model: Object,
        authStore:Object,
    },
    components: {
        FirstScreen,
        DownloadSection,
        AvatarsSection,
        PandoraSection,
        CommunityFoundationSection,
        RewardsSection,
        MessagingPlatformsSection,
        SocialPlatformSection,
        AutonomyProposalSection,
        ProposalDecisionSection
    }
}
</script>
