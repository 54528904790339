<template>
    <div class="travel-page">
        <div class="first-screen-image">
            <PaginationSlider :slides="travelSlides" effect="fade" :pagination-outside="true" desktopHeight="450px"
                tabletHeight="350px" mobileHeight="175px" pagination-id="pagination-1">
                <template #slide="{ slide }">
                    <div class="travel-main-slide-img">
                        <img :src="slide.src" :alt="slide.alt" />
                    </div>
                </template>
            </PaginationSlider>

        </div>

        <div class="travel-data">
            <div class="travel-data-elem">
                <div class="large-body-text">
                    <p>{{ $t('travelPage.upTo') }}</p>
                </div>
                <div class="travel-item-content">
                    <div class="travel-item-data">
                        <p>{{ upToBookings }}</p>
                    </div>
                </div>
                <div class="large-body-text">
                    <p>{{ $t('travelPage.bookingsMade') }}</p>
                </div>
            </div>

            <div class="travel-data-elem">

                <div class="large-body-text">
                    <p>{{ $t('travelPage.upTo') }}</p>
                </div>

                <div class="travel-item-content">
                    <div class="travel-item-data-img">
                        <img src="../assets/images/logo/luca-logo.svg" alt="luca-logo">
                    </div>
                    <div class="travel-item-data">
                        <p>{{ this.totalBurned }}</p>
                    </div>
                </div>

                <div class="travel-item-text-wrapper">
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.amountLUCAburnt') }}</p>
                    </div>
                    <div class="travel-item-text-img" @mouseenter="showTooltip('tooltip1')"
                        @mouseleave="hideTooltip('tooltip1')"
                        @click="toggleTooltip('tooltip1')">
                        <img src="../assets/images/icons/info-icon.svg" alt="info-icon">

                        <div class="tooltip" v-if="tooltips.tooltip1">
                            <div class="text-xxs">
                                <p> {{ $t('travelPage.infoTooltipText') }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="wallet-adress-wrapper">

                    <div v-for="(item, index) in addresses" :key="index" class="wallet-adress-item">
                        <div class="text-xxs">
                            <p>{{ item.type }}</p>
                        </div>
                        <div class="wallet-adress-wrapper">
                            <div class="text-xs">
                                <p>{{ shortenAddress(item.address) }}</p>
                            </div>
                            <div class="wallet-adress-copy-img" @click="copyToClipboard(item.address)">
                                <img src="../assets/images/icons/copy.svg" alt="copy">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="travel-data-elem">

                <div class="large-body-text">
                    <p>{{ $t('travelPage.upTo') }}</p>
                </div>

                <div class="travel-item-content">
                    <div class="travel-item-data">
                        <p>${{ upToMiles }}</p>
                    </div>
                </div>
                <div class="travel-item-text-wrapper">
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.totalAmountSaved') }}</p>
                    </div>

                    <div class="travel-item-text-img" @mouseenter="showTooltip('tooltip2')"
                        @mouseleave="hideTooltip('tooltip2')"
                        @click="toggleTooltip('tooltip2')">
                        <img src="../assets/images/icons/info-icon.svg" alt="info-icon" />

                        <div class="tooltip" v-if="tooltips.tooltip2">
                            <div class="text-xxs">
                                <p>{{ $t('travelPage.usersSavingBooking') }}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="hotel-deals-text">
            <p v-html="$t('travelPage.optimizeJourneys')"></p>
        </div>

        <Section :title="$t('travelPage.hotels')" class="travel-section-wrapper">

            <div class="travel-content">
                <div class="text">
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.bookHotelThroughATM') }}</p>
                    </div>

                    <div class="travel-logo">
                        <img src="../assets/images/logo/atmtravellogo.png" alt="atmtravellogo">
                    </div>
                </div>
                <div class="desc">
                    <div class="body-text">
                        <p>{{ $t('travelPage.hotelExclusives') }}</p>
                    </div>

                </div>
            </div>

            <div class="hotels-content">
                <PaginationSlider :slides="hotelsSlides" effect="fade" :pagination-outside="true" desktopHeight="353px"
                    tabletHeight="350px" mobileHeight="350px" pagination-id="pagination-2">
                    <template #slide="{ slide }">
                        <div class="travel-slide-content">
                            <img :src="slide.src" :alt="slide.alt" />
                            <div class="content-text-wrapper">
                                <div class="bold-body-text">
                                    <p>{{ $t('travelPage.saveUpTo') }}</p>
                                </div>
                                <div class="large-body-text">
                                    <p>{{ $t('travelPage.yourNextHotelBooking') }}</p>
                                </div>
                                <a href="https://travel.atm.network" target="_blank">
                                    <div class="active-button">
                                        <p>{{ $t('travelPage.bookHotel') }}</p>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </template>
                </PaginationSlider>
            </div>
            <div class="flights-content-text">
                <p v-html="$t('travelPage.bookHolidayWithLuca')"></p>
            </div>
        </Section>

        <Section :title="$t('travelPage.flights')" class="travel-section-wrapper">
            <div class="travel-content">
                <div class="text">
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.flightThroughATM') }}</p>
                    </div>
                    <div class="text-logo-wrapper">
                        <div class="travel-logo">
                            <img src="../assets/images/logo/atmtravellogo.png" alt="atmtravellogo">
                        </div>
                        <div class="large-body-text">
                            <p>{{ $t('travelPage.with') }}</p>
                        </div>
                        <a href="https://www.trip.com/t/dfVk03pnkZ1" target="_blank" class="trip-logo">
                            <img src="../assets/images/logo/trip.svg" alt="trip-logo">
                        </a>
                    </div>
                </div>
                <div class="desc">
                    <div class="body-text">
                        <p>{{ $t('travelPage.effortlessBooking') }}</p>
                    </div>

                </div>
            </div>



            <div class="hotels-content">
                <PaginationSlider :slides="flightsSlides" effect="fade" :pagination-outside="true" desktopHeight="353px"
                    tabletHeight="350px" mobileHeight="350px" pagination-id="pagination-3">
                    <template #slide="{ slide }">
                        <div class="travel-slide-content">
                            <img :src="slide.src" :alt="slide.alt" />
                            <div class="content-text-wrapper">
                                <div class="bold-body-text">
                                    <p>{{ $t('travelPage.saveUpTo25') }}</p>
                                </div>
                                <div class="large-body-text">
                                    <p>{{ $t('travelPage.yourNextFlight') }}</p>
                                </div>
                                <a href="https://www.trip.com/t/dfVk03pnkZ1" target="_blank">
                                    <div class="active-button">
                                        <p>{{ $t('travelPage.bookFlight') }}</p>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </template>
                </PaginationSlider>
            </div>

        </Section>



        <!-- <HotelDeals /> -->

        <AssociatePartnerSec />
        <DownloadSection />
    </div>
</template>
<script>


import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBlockChainStore } from '@/store/blockchain';
import { convertToEth } from '@/utils/formatters';
import { ElNotification } from 'element-plus';
import AssociatePartnerSec from '../components/travelPage/AssociatePartnerSec.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import HotelDeals from '../components/travelPage/hotelDeals.vue';
import Section from '../components/common/section.vue';
import travelData from '@/assets/data/travelData.json';
import PaginationSlider from '../components/common/paginationSlider.vue';

export default {
    name: 'TravelPage',
    components: {
        AssociatePartnerSec,
        DownloadSection,
        HotelDeals,
        Section,
        PaginationSlider
    },
    setup() {

        const { t } = useI18n();

        const upToBookings = travelData.upToBookings;
        const upToMiles = travelData.upToMiles;
        const totalBurned = ref(null);
        const addresses = ref([
            { type: t('walletAddress'), address: '0x57ee36a605ddd5a6ad5e3b3b3979667da13da605' },
            { type: t('travelPage.smartContract'), address: '0xbA664Eb753bD75cE86EF1F0B651ABF4Ad0D9C30A' },
        ]);

        const features = [
            { text: "findFast" },
            { text: "bookWithEase" },
            { text: "saveBig" },
        ];

        const travelSlides = [
            { src: '/images/travelPage/travel-main-1.webp', alt: 'Slide 1' },
            { src: '/images/travelPage/travel-main-2.webp', alt: 'Slide 2' },
            { src: '/images/travelPage/travel-main-3.webp', alt: 'Slide 3' },
        ];

        const hotelsSlides = [
            { src: '/images/travelPage/hotel1.webp', alt: 'Slide 1' },
            { src: '/images/travelPage/hotel2.webp', alt: 'Slide 2' },
            { src: '/images/travelPage/hotel3.webp', alt: 'Slide 3' },
        ];

        const flightsSlides = [
            { src: '/images/travelPage/flight1.webp', alt: 'Slide 1' },
            { src: '/images/travelPage/flight2.webp', alt: 'Slide 2' },
            { src: '/images/travelPage/flight3.webp', alt: 'Slide 3' },
        ];



        const tooltips = ref({
            tooltip1: false,
            tooltip2: false,
        });

        const isMobile = computed(() => window.innerWidth <= 1024);

        const showTooltip = (tooltipId) => {
            if (!isMobile.value) {
                tooltips.value[tooltipId] = true;
            }
        };

        const hideTooltip = (tooltipId) => {
            if (!isMobile.value) {
                tooltips.value[tooltipId] = false;
            }
        };

        const toggleTooltip = (tooltipId) => {
            if (isMobile.value) {
                tooltips.value[tooltipId] = !tooltips.value[tooltipId];
            }
        };





        const textList = computed(() => {
            return features.map(item => ({
                ...item,
                text: t(`travelPage.${item.text}`),
            }));
        });

        const screenWidth = ref(window.innerWidth);
        const isLargeScreen = computed(() => screenWidth.value > 900);


        const updateScreenWidth = () => {
            screenWidth.value = window.innerWidth;
        };

        const shortenAddress = (address) => {
            if (!address || address.length < 5) return address;
            return `${address.slice(0, 6)}...${address.slice(-6)}`;
        };

        const copyToClipboard = (address) => {
            navigator.clipboard.writeText(address).then(() => {

                ElNotification({
                    title: 'Success',
                    message: 'Copied successfully!',
                    type: 'success',
                    duration: 3000,
                });
            }).catch((err) => {

                ElNotification({
                    title: 'Error',
                    message: 'Failed to copy.',
                    type: 'error',
                    duration: 3000,
                });
                console.error('failed to copy:', err);
            });
        };

        const getTotalBurned = async () => {
            const blockChainStore = useBlockChainStore();

            try {
                const contract = await blockChainStore.getContract('LucaVoid');
                const tokenAddress = await blockChainStore.getContractAddress(56, 'LUCA');
                const burnTypeId = 2;

                const totalBurnedValue = await contract.methods.getTotalBurnedByType(tokenAddress, burnTypeId).call();
                console.error('getTotalBurned totalBurnedValue :', totalBurnedValue);

                totalBurned.value = convertToEth(totalBurnedValue);
            } catch (e) {
                console.error('getTotalBurned ERROR :', e);
            }
        };


        onMounted(() => {
            window.addEventListener('resize', updateScreenWidth);
            getTotalBurned();
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateScreenWidth);
        });


        return {
            totalBurned,
            addresses,
            textList,
            isLargeScreen,
            shortenAddress,
            copyToClipboard,
            upToBookings,
            upToMiles,
            travelSlides,
            hotelsSlides,
            flightsSlides,
            tooltips,
            showTooltip,
            hideTooltip,
            toggleTooltip,
        };
    },
};
</script>

<style scoped>
@import '@/assets/style/travelPage/travelPage.scss';
</style>
